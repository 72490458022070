import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1100,
        display: "flex",
        flexDirection: "row",
        padding: `.5rem`,
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `#d0d0d0`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <nav
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <Link to="/about">About</Link>
        <Link to="/clients">Clients</Link>
        <Link to="/testimonials">Testimonials</Link>
        <Link to="/contact">Contact</Link> |
        <Link to="/blog">Blog</Link> */}
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
